import axios from 'axios'
// import store from './store/index'
 import router from './router/index'
import { Message } from 'element-ui'
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = true
axios.interceptors.response.use(
  response => {
    if (response.data.code === -999) {
      localStorage.removeItem('email')
      localStorage.removeItem('username')
      localStorage.removeItem('name')
      localStorage.removeItem('department')
      localStorage.removeItem('company_name')
      localStorage.removeItem('position')
      let route = ['/login', '/Find', '/ResetTip', '/reset_password/email', '/register', '/validatemail', '/validatemail','/accounts/email_activate/']
      if (route.indexOf(router.currentRoute.path == -1)){
        router.replace({ path: '/login' })
      }
    } else {
      return response
    }
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 500:
          Message.error('服务器异常，请稍后再试！');
      }
    }
    return Promise.reject(error)
  },

)
export default axios
