import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import axios from '../https'
const Login = () => import('@/pages/Login.vue')//登录
const Register = () => import('@/pages/Register.vue')//注册
const FindPassword = () => import('@/pages/FindPassword.vue')//找回密码
const ResetTip = () => import('@/pages/ResetTip.vue')//重置密码提示
const ResetPassword = () => import('@/pages/ResetPassword.vue')//重置密码
const Userinfo = () => import('@/pages/Userinfo.vue')//用户信息
const ValidateEmail = () => import('@/pages/ValidateEmail.vue')//邮箱验证
const RegisterOk = () => import('@/pages/RegisterOk.vue')//注册成功
const Home = () => import('@/pages/Home.vue')//主页
const editUers = () => import('@/component/editUers.vue') //用户名三角按钮编辑个人信息
const AllRourse = () => import('@/component/AllRourse.vue')//全部素材
const materialDetail = () => import('@/pages/materialDetail.vue') //素材详情
const materialUpload = () => import('@/pages/materialUpload.vue') //素材上传
const myCollection = () => import('@/pages/myCollection.vue') //我的收藏
const myMaterial = () => import('@/pages/myMaterial.vue') //我的素材
const Tag = () => import('@/pages/tag.vue') //标签管理-标签
const TagGroup = () => import('@/pages/tagGroup.vue') //标签管理-标签组
const Recycle = () => import('@/component/recycle.vue')//回收站
const Uploading = () => import('@/component/uploading.vue')//上传记录
const WatchList = () => import('@/component/watchList.vue')//观看列表

Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [

  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/Find',
    name: 'FindPassword',
    component: FindPassword
  },
  {
    path: '/ResetTip',
    name: 'ResetTip',
    component: ResetTip
  },
  {
    path: '/reset_password/email',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/userinfo',
    name: 'Userinfo',
    component: Userinfo
  },
  {
    path: '/validatemail',
    name: 'ValidateEmail',
    component: ValidateEmail
  },
  {
    path: '/accounts/email_activate/',
    name: 'RegisterOk',
    component: RegisterOk,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    // redirect:'/allrourse',
    // 路由到全部素材页面
    children: [
      {
        path: '/editUers',
        name: 'editUers',
        component: editUers
      },
      {
        path: '/allrourse',
        name: 'allrourse',
        component: AllRourse
      },
      {
        path: '/material_detail',
        name: 'materialDetail',
        component: materialDetail
      },
      {
        path: '/material_upload',
        name: 'materialUpload',
        component: materialUpload
      },
      {
        path: '/my_collection',
        name: 'myCollection',
        component: myCollection
      },
      {
        path: '/my_material',
        name: 'myMaterial',
        component: myMaterial
      },
      {
        path: '/tag',
        name: 'Tag',
        component: Tag
      },
      {
        path: '/tag_group',
        name: 'TagGroup',
        component: TagGroup
      },
      {
        path: '/recycle',
        name: 'Recycle',
        component: Recycle
      },
      {
        path: '/uploading',
        name: 'Uploading',
        component: Uploading
      },
      {
        path: '/watchlist',
        name: 'watchlist',
        component: WatchList
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const nextRoute = ['Home', 'editUers', 'allrourse', 'materialDetail', 'materialUpload', 'myCollection',
    'myMaterial', 'Tag', 'TagGroup', 'Recycle', 'Uploading', 'watchlist','Userinfo'];
  // 1.登录未登录访问login不同，未登录放行，登录跳转到/allrourse
  //  2.部分路由登录后不可访问，未登录可访问
  //
  let email = localStorage.getItem('email');

  if (email === null || email === '') { //未登录
    if (nextRoute.indexOf(to.name) >= 0) {
      next('/login')
    }else{
      next()
    }
  }else{ //已登录
    if (to.path === '/' || nextRoute.indexOf(to.name) == -1){
      if (to.path === '/login' && (email === null || email === '')){
        next('/login')
      }else{
        return next({ path: '/allrourse' })
      }
    }else{
      next()
    }
  }
})



export default router
